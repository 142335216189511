function HomeSection() {
  return (
    <div className="home-section">
      <h1>Mike Majdalani</h1>
      <h3>
        Welcome to my website. Here, you will discover everything you need to
        know about my life's work. You can explore my skills, delve into my
        journey through years of blogging, and if you'd like to connect, please
        don't hesitate to reach out!
      </h3>

      <h2 className="home-section__select-text">
        {" "}
        Select a box and discover my journey
      </h2>
    </div>
  );
}
export default HomeSection;
