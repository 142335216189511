import { useNavigate } from "react-router-dom";

import Helmet from "react-helmet";

function AccountManagerAtKingsmen() {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>Marketing Adventures: Account Manager at Kingsmen</title>
        <meta
          name="description"
          content="Embarked on a journey as an Account Manager, overseeing three prominent accounts in the UAE for over a year. Dive into this experience"
        />
        <link
          rel="canonical"
          href="https://mikemajdalani.com/account-manager-at-kingsmen/"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Marketing Adventures: Account Manager at Kingsmen"
        />
        <meta
          property="og:description"
          content="Embarked on a journey as an Account Manager, overseeing three prominent accounts in the UAE for over a year. Dive into this experience"
        />
        <meta
          property="og:url"
          content="https://mikemajdalani.com/account-manager-at-kingsmen/"
        />
        <meta property="og:site_name" content="Mike Majdalani" />
      </Helmet>
      <div className="mainContainer">
        <div className="blogcontainer">
          <h1>Marketing Adventures: Account Manager at Kingsmen</h1>
          <div className="seperator" />
          <p>
            In the bustling heart of <strong>Dubai's</strong> thriving{" "}
            <strong>marketing</strong> landscape, I found myself at{" "}
            <a href="https://www.linkedin.com/company/kingsmen-technology/?originalSubdomain=ae">
              Kingsmen Agency
            </a>
            , a prominent player in the field of <strong>360 marketing</strong>{" "}
            and advertising management. As an{" "}
            <a href="https://resources.workable.com/account-manager-job-description">
              Account Manager
            </a>
            , my role was an exhilarating blend of strategic thinking, client
            engagement, and collaboration with a talented team of professionals.
            At <strong>Kingsmen</strong>, we took pride in being one of the
            leading <strong>marketing management</strong> agencies in{" "}
            <a href="https://www.visitdubai.com/en/">Dubai</a>, setting high
            standards for ourselves and the industry as a whole.
          </p>

          <div className="seperator" />
          <h3>
            <strong>The Life of an Account Manager</strong>
          </h3>
          <div className="seperator" />
          <p>
            Being an <strong>Account Manager</strong> at{" "}
            <strong>Kingsmen Agency</strong> was a multifaceted role that
            required juggling various responsibilities to ensure client
            satisfaction. My daily tasks revolved around managing several
            significant accounts across the <a href="https://u.ae/en">UAE</a>.{" "}
            Here's a glimpse of my role in action:
          </p>
          <div className="seperator" />
          <p>
            <strong>Content Management:</strong> Collaborating closely with
            graphic designers, I was responsible for ensuring that{" "}
            <a href="https://backlinko.com/hub/content/calendar">
              content calendars
            </a>{" "}
            were meticulously crafted on a monthly basis. These calendars played
            a crucial role in guiding our marketing efforts.
          </p>
          <div className="seperator" />
          <p>
            <strong>Social Media Wizardry:</strong> From Facebook and Instagram
            to YouTube and LinkedIn, I worked hand-in-hand with
            <a href="https://www.procopywriters.co.uk/2016/06/why-copywriters-need-a-strong-content-calendar/">
              {" "}
              copywriters{" "}
            </a>
            to schedule and post engaging content on all these platforms. Our
            goal was to keep the online presence of our clients vibrant and
            appealing.
          </p>
          <div className="seperator" />
          <p>
            <strong>Website Oversight:</strong> In partnership with web
            developers and{" "}
            <a href="https://searchengineland.com/guide/what-is-seo">SEO </a>
            sepcialists, I meticulously reviewed our clients' websites to ensure
            seamless functionality. A smooth online experience is essential for
            attracting and retaining customers in today's digital age.
          </p>
          <div className="seperator" />
          <p>
            <strong>Crafting Campaigns:</strong> Collaborating with media
            managers, I was responsible for setting up effective{" "}
            <a href="https://directiveconsulting.com/resources/glossary/ad-campaigns/">
              ad campaigns{" "}
            </a>
            to drive conversions for our clients. Strategic media planning was
            the key to achieving this.
          </p>
          <div className="seperator" />
          <p>
            <strong>Workflow Management:</strong> To keep all these moving parts
            in sync, we utilized the efficient project management tool
            <a href="https://trello.com/"> Trello</a>, ensuring that nothing
            fell through the cracks.
          </p>

          <div className="seperator" />

          <img
            src="blogs/account-manager-at-kingsmen/account-manager-at-kingsmen-01-f.png"
            alt="Account Manager at Kingsmen"
          />

          <div className="seperator" />

          <div className="seperator" />
          <h3>
            Now, let's delve into a few of the remarkable accounts I had the
            privilege to work on during my tenure at Kingsmen Agency.
          </h3>
          <div className="seperator" />
          <p>
            <strong>
              EIFM (Emirates International Facility Management LLC)
            </strong>
          </p>
          <p>
            <a href="https://www.eifm.ae/">EIFM</a>, a subsidiary of the Das
            Holding Group of Companies, is a prominent player in the UAE's
            facility management sector. Established in 1996, EIFM offers
            high-quality, cost-effective, and integrated facility management
            solutions. My duties included developing and executing tailored
            marketing strategies to enhance EIFM's visibility and reach.
          </p>
          <div className="seperator" />
          <p>
            <strong>Select Market</strong>
          </p>
          <p>
            <a href="https://www.selectmarket.ae/">Select Market</a>, another
            subsidiary of the <a href="https://dasholding.ae/">Das Holding </a>
            Group, is a leading supermarket chain in Abu Dhabi. Apart from
            managing their social media presence and campaigns, our team had the
            exciting responsibility of producing monthly booklets to highlight
            promotions and offers, ensuring that the marketing message reached
            their customers effectively.
          </p>
          <div className="seperator" />
          <p>
            <strong>East & West International Group (EWIG)</strong>
          </p>
          <p>
            <a href="https://www.eastandwest.ae/">EWIG</a>, founded in 1993, is
            a prominent integrated Real Estate Group in the UAE. My role
            involved crafting comprehensive marketing strategies to showcase
            their real estate offerings, leveraging various platforms to attract
            potential buyers and investors.
          </p>
          <div className="seperator" />
          <h3>
            In addition to these remarkable accounts, I also had the opportunity
            to work briefly on other exciting projects, including:
          </h3>
          <div className="seperator" />
          <p>
            <strong>Volofinance:</strong> A leading{" "}
            <a href="https://volo.finance/">Forex Broker</a> in Dubai, where I
            contributed to promoting their trading and investment products.
          </p>
          <p>
            {" "}
            <strong>MyTable:</strong> A reservation{" "}
            <a href="https://mytable.world/">service</a> provider in Dubai,
            where I assisted in marketing restaurant reservations and event
            booking services.{" "}
          </p>
          <p>
            <strong>Cityscape Dubai:</strong> An industry event, where I helped
            drive participation and engagement to stay ahead of real estate
            trends.{" "}
          </p>

          <div className="seperator" />

          <img
            src="blogs/account-manager-at-kingsmen/account-manager-at-kingsmen-02.jpg"
            alt="Account Manager at Kingsmen"
          />

          <div className="seperator" />

          <h2>It was a nice journey!</h2>
          <div className="seperator" />
          <p>
            My journey as an <strong>Account Manager</strong> at{" "}
            <strong>Kingsmen Agency</strong> in Dubai was a whirlwind of
            experiences, challenges, and immense learning. The opportunity to
            work with such prestigious clients in the UAE not only sharpened my{" "}
            <a href="https://www.coursera.org/articles/marketing-skills">
              marketing skills{" "}
            </a>
            but also broadened my horizons.
          </p>
          <div className="seperator" />
          <p>
            As I move forward in my career, I will always cherish my time at{" "}
            <strong>Kingsmen Agency</strong>, where I contributed to the success
            stories of some of the region's most influential brands. It was a
            rewarding experience, and I am grateful for the chance to be part of
            a dynamic team in one of Dubai's{" "}
            <a href="https://clutch.co/ae/agencies/digital-marketing/dubai">
              top marketing agencies
            </a>
            . Here's to many more exciting adventures in the ever-evolving world
            of marketing!
          </p>
          <div className="seperator" />
          <button type="button" onClick={() => navigate("/#blogs")}>
            Go back
          </button>

          <div className="seperator" />
        </div>
      </div>
    </>
  );
}
export default AccountManagerAtKingsmen;
