import { useState } from "react";

const Filter = ({ tags, onSelectTag }) => {
  const [selectedTag, setSelectedTag] = useState("");

  const handleTagSelect = (tag) => {
    setSelectedTag(tag);
    onSelectTag(tag);
  };

  const handleShowAll = () => {
    setSelectedTag("");
    onSelectTag("");
  };
  return (
    <div className="filter-container">
      <button
        className={selectedTag === "" ? "active" : ""}
        onClick={handleShowAll}
      >
        All
      </button>
      {tags.map((tag) => (
        <button
          key={tag}
          className={selectedTag === tag ? "active" : ""}
          onClick={() => handleTagSelect(tag)}
        >
          {tag}
        </button>
      ))}
    </div>
  );
};

export default Filter;
