import { Link } from "react-router-dom";
import { useState } from "react";

const BlogCard = ({ title, tags, description, imageURL, link }) => {
  const getTagColorClass = (tagName) => {
    // Define a mapping of tag names to CSS classes
    const tagColorClasses = {
      General: "blog-tag-teal",
      Development: "blog-tag-default",
      "Content Creation": "blog-tag-purple",
      "Success Stories": "blog-tag-gold",
      Marketing: "blogs-tag-greenish",
      // Add more tag names and corresponding CSS classes as needed
    };

    // Return the corresponding CSS class for the given tag name, or a default class if not found
    return tagColorClasses[tagName] || "blog-tag-default";
  };
  const [loaded, setLoaded] = useState(false);
  return (
    <Link to={link} className="blog-card">
      <div className="blog-card-header">
        <img
          className="blog-skeleton"
          src={imageURL}
          alt={title}
          loading="lazy"
        />
      </div>
      <div className="blog-card-body">
        <div className="blog-card-body-tags">
          {tags.map((tag) => {
            return (
              <span className={`blog-tag ${getTagColorClass(tag)}`}>{tag}</span>
            );
          })}
        </div>
        <h4>{title}</h4>
        <p>{description}</p>
      </div>
    </Link>
  );
};
export default BlogCard;
