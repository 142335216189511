function SkillCard({ color, text, icon }) {
  return (
    <div
      data-aos="fade-up"
      data-aos-delay="200"
      className={`skill__buttons`}
      style={{ color: color }}
    >
      {icon}
      <div className="skills__button--d-block">{text}</div>
    </div>
  );
}
export default SkillCard;
