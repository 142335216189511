import { useNavigate } from "react-router-dom";

import Helmet from "react-helmet";

function SMSSecurityNFTProject() {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>S.M.S Security: Where NFTs Meet Surveillance</title>
        <meta
          name="description"
          content="S.M.S Security is an NFT project where I coded the smart contract and played a role in guiding and overseeing the project's art design"
        />
        <link
          rel="canonical"
          href="https://mikemajdalani.com/s-m-s-security-nft-project/"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="S.M.S Security: Where NFTs Meet Surveillance"
        />
        <meta
          property="og:description"
          content="S.M.S Security is an NFT project where I coded the smart contract and played a role in guiding and overseeing the project's art design"
        />
        <meta
          property="og:url"
          content="https://mikemajdalani.com/s-m-s-security-nft-project/"
        />
        <meta property="og:site_name" content="Mike Majdalani" />
      </Helmet>
      <div className="mainContainer">
        <div className="blogcontainer">
          <h1>S.M.S Security: Where NFTs Meet Surveillance</h1>
          <div className="seperator" />
          <p>
            In a world increasingly reliant on <strong>technology</strong> and{" "}
            <strong>security</strong>, the fusion of <strong>NFTs</strong> and{" "}
            <strong>security services</strong> may seem unlikely, but{" "}
            <strong>innovation</strong> knows no bounds. Allow me to introduce
            you to a groundbreaking project I had the privilege of developing -{" "}
            <a href="https://www.linkedin.com/company/smssecurity-co/">
              S.M.S Security.{" "}
            </a>
            At its core, <strong>S.M.S Security</strong> is a security provider
            agency that specializes in establishing surveillance cameras and
            providing bodyguard services. But it's not just any security agency;
            it's one that places a unique twist on security by intertwining{" "}
            <strong>NFTs</strong> with safety.
          </p>
          <div className="seperator" />
          <p>
            My role in this endeavor was <strong>multifaceted</strong>. I not
            only provided the <strong>artistic direction</strong> for the{" "}
            <a href="https://www.simplilearn.com/tutorials/blockchain-tutorial/what-is-nft#:~:text=NFTs%20or%20Non%2DFungible%20Tokens,each%20unit%20can%20be%20interchanged.">
              NFTs
            </a>{" "}
            but also collaborated with a <strong>graphic designer</strong> to
            bring them to life. Additionally, I generated the{" "}
            <strong>NFTs</strong> using a specialized NFT{" "}
            <a href="https://www.designity.com/blog/how-to-create-an-nft-in-5-steps">
              generator script
            </a>{" "}
            and was responsible for coding the <strong>smart contract</strong>{" "}
            that underpins this fascinating project.
          </p>

          <div className="seperator" />
          <img
            src="blogs/s-m-s-security-nft-project/s-m-s-security-nft-project-01-f.jpg"
            alt="S.M.S Security"
          />

          <div className="seperator" />

          <div className="seperator" />
          <h2>The Artistic Vision</h2>
          <div className="seperator" />
          <p>
            The heart and soul of <strong>S.M.S Security's</strong>{" "}
            <strong>NFTs</strong> lie in their{" "}
            <strong>artistic representation</strong>. These{" "}
            <strong>NFTs</strong> depict various security guards, each with a
            distinct set of traits. From different hairstyles and facial
            features to diverse attire, including t-shirts, sunglasses, and
            more, these guards are more than just{" "}
            <a href="https://www.fool.com/investing/stock-market/market-sectors/financials/non-fungible-tokens/digital-collectibles/">
              digital collectibles;{" "}
            </a>
            they're a testament to the <strong>creative potential</strong> of{" "}
            <strong>NFTs</strong>.
          </p>
          <div className="seperator" />
          <p>
            What makes these <strong>NFTs</strong> even more intriguing are the{" "}
            <a href="https://www.binance.com/en/blog/nft/a-guide-to-nft-rarity-9071635817709795895">
              rarity perks
            </a>{" "}
            associated with them. Some guards possess unique combinations of
            traits that make them <strong>rarer</strong> and more coveted among
            collectors. The rarity system adds a layer of{" "}
            <strong>excitement</strong> to the project, encouraging users to
            seek out the most <strong>exclusive NFTs</strong>.
          </p>
          <div className="seperator" />
          <p>
            I played a pivotal role in guiding the design of these{" "}
            <strong>NFTs</strong>, ensuring that they align with the overall
            vision of <strong>S.M.S Security</strong>.
          </p>

          <div className="seperator" />

          <img
            src="blogs/s-m-s-security-nft-project/s-m-s-security-nft-project-02-f.jpg"
            alt="S.M.S Security"
          />

          <div className="seperator" />

          <h2>The Smart Contract</h2>
          <div className="seperator" />
          <p>
            The backbone of <strong>S.M.S Security's</strong>{" "}
            <strong>NFT</strong> project is its{" "}
            <a href="https://hedera.com/learning/smart-contracts/nft-smart-contract">
              smart contract
            </a>
            , a vital piece of code that manages the creation, ownership, and
            trading of <strong>NFTs</strong>. I developed a custom{" "}
            <a href="https://eips.ethereum.org/EIPS/eip-721">ERC-721</a> smart
            contract and deployed it on the{" "}
            <a href="https://polygon.technology/">Polygon blockchain</a>. This
            choice of <strong>blockchain</strong> offers numerous benefits,
            including faster transaction times and lower fees compared to the
            Ethereum mainnet.
          </p>
          <div className="seperator" />
          <p>
            By leveraging the power of <strong>Polygon</strong>,{" "}
            <strong>S.M.S Security</strong> ensures that its{" "}
            <strong>NFTs</strong> are not only unique but also accessible to a
            broader audience, minimizing the environmental impact often
            associated with{" "}
            <a href="https://cointelegraph.com/learn/polygon-blockchain-explained-a-beginners-guide-to-matic">
              {" "}
              blockchain transactions.
            </a>
          </p>
          <div className="seperator" />

          <a
            className="button"
            href="https://polygonscan.com/address/0xC65fB61Bdcc3a0a93Cf00b395BbA3361122FBfc2#code"
          >
            Source Code
          </a>

          <div className="seperator" />
          <img
            src="blogs/s-m-s-security-nft-project/s-m-s-security-nft-project-03-f.jpg"
            alt="S.M.S Security"
          />

          <div className="seperator" />

          <p>
            In conclusion, <strong>S.M.S Security</strong> is a remarkable
            fusion of <strong>security services</strong> and{" "}
            <strong>NFT innovation</strong>. As the developer responsible for
            the art, <strong>NFT generation</strong>, and smart contract, I am
            proud to have played a central role in bringing this{" "}
            <a href="https://opensea.io/collection/s-m-s-security">project </a>
            to life.
          </p>
          <div className="seperator" />
          <p>
            While my role focused on the technical and creative aspects, it's
            essential to note that the client took charge of marketing and
            selling these <strong>NFTs</strong> to their clients.{" "}
            <strong>S.M.S Security's</strong> foray into the{" "}
            <strong>NFT space</strong> exemplifies how{" "}
            <strong>innovation</strong> can breathe new life into traditional
            industries, offering unique and exciting opportunities for both
            businesses and collectors alike. The{" "}
            <a href="https://www.globsec.org/our-expertise/future-security">
              future of <strong>security</strong>{" "}
            </a>
            has never looked so intriguing, and I'm excited to see how{" "}
            <strong>S.M.S Security</strong> continues to evolve and redefine the
            boundaries of what's possible in the realm of <strong>NFTs</strong>{" "}
            and <strong>security services</strong>.
          </p>
          <div className="seperator" />
          <button type="button" onClick={() => navigate("/#blogs")}>
            Go back
          </button>

          <div className="seperator" />
        </div>
      </div>
    </>
  );
}
export default SMSSecurityNFTProject;
