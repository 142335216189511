import { useState, useRef } from "react";
import { AiOutlineSend } from "react-icons/ai";
import emailjs from "@emailjs/browser";
import { ThreeDots, Hearts } from "react-loader-spinner";
function ContactForm() {
  const [formInputs, setFormInputs] = useState({
    from_name: "",
    message: "",
    request_cv: false,
  });
  const [formError, setformError] = useState("");
  const [formSuccess, setformSuccess] = useState(false);
  const [formLoading, setformLoading] = useState(false);
  const form = useRef();
  const handleChange = (e) => {
    if (e.target.type === "checkbox") {
      setFormInputs((previousState) => ({
        ...previousState,
        [e.target.name]: e.target.checked,
      }));
    } else {
      setFormInputs((previousState) => ({
        ...previousState,
        [e.target.name]: e.target.value,
      }));
    }
  };

  const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
  const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
  const userId = process.env.REACT_APP_EMAILJS_USER_ID;

  const regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (formInputs.from_name === "" || formInputs.message === "") {
      setformError("Fields are empty!");
    } else {
      if (!regex.test(formInputs.from_name)) {
        setformError("Wrong Email Format!");
      } else {
        setformLoading(true);

        emailjs.sendForm(serviceId, templateId, form.current, userId).then(
          (result) => {
            setformLoading(false);
            setformError("");
            setformSuccess(true);
            setFormInputs({
              from_name: "",
              message: "",
              request_cv: false,
            });
          },
          (error) => {
            setformError("Internal Error!");
          }
        );
      }
    }
  };
  return (
    <div className="contact-form">
      <div className="contact-form__column">
        <Hearts
          height="40"
          width="80"
          color="#000000"
          ariaLabel="hearts-loading"
          wrapperStyle={{}}
          wrapperClass="contact-form--alignCenter"
          visible={true}
        />
        <span className="contact-form--alignCenter">Let us get in touch</span>
      </div>
      <form ref={form}>
        <div className="contact-form__column">
          <input
            value={formInputs.from_name}
            onChange={handleChange}
            type="email"
            name="from_name"
            placeholder="Email Address"
            disabled={formSuccess}
          ></input>
          <textarea
            name="message"
            value={formInputs.message}
            onChange={handleChange}
            rows="5"
            placeholder="Message"
            disabled={formSuccess}
          ></textarea>
          <div className="contact-form__request-cv">
            <input
              type="checkbox"
              name="request_cv"
              checked={formInputs.request_cv}
              onChange={handleChange}
              disabled={formSuccess}
              className="contact-form__checkbox"
            />
            <span>Request Resume</span>
          </div>
        </div>
      </form>
      {formError !== "" && (
        <div className="contact-form--error">{formError}</div>
      )}
      {formSuccess && (
        <div className="contact-form--success">Message sent, thank you!</div>
      )}
      {formLoading ? (
        <div className="contact-form__button contact-form__button--loading">
          <ThreeDots
            height="25"
            width="25"
            radius="9"
            color="#fff"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      ) : formSuccess ? (
        <div className="contact-form__button contact-form__button--disabled">
          <AiOutlineSend />
        </div>
      ) : (
        <div
          className="contact-form__button contact-form__button--hoverable"
          onClick={handleSubmit}
        >
          <AiOutlineSend />
        </div>
      )}
    </div>
  );
}

export default ContactForm;
