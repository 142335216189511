import { useNavigate } from "react-router-dom";

import Helmet from "react-helmet";

function WebDeveloperAtUSJ() {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>Innovative SIS Project at USJ</title>
        <meta
          name="description"
          content="Explore the intriguing project I'm currently employed on with the esteemed University of Saint Joseph's development team"
        />
        <link
          rel="canonical"
          href="https://mikemajdalani.com/web-developer-at-usj/"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Innovative SIS Project at USJ" />
        <meta
          property="og:description"
          content="Explore the intriguing project I'm currently employed on with the esteemed University of Saint Joseph's development team"
        />
        <meta
          property="og:url"
          content="https://mikemajdalani.com/web-developer-at-usj/"
        />
        <meta property="og:site_name" content="Mike Majdalani" />
      </Helmet>
      <div className="mainContainer">
        <div className="blogcontainer">
          <h1> Innovative SIS Project at USJ</h1>
          <div className="seperator" />
          <p>
            In the <strong>ever-evolving landscape of education</strong>,
            universities are constantly seeking ways to{" "}
            <strong>enhance learning</strong> and{" "}
            <strong>streamline processes</strong>. The{" "}
            <a href="https://www.usj.edu.lb/">University of Saint Joseph</a>{" "}
            (USJ) embarked on a <strong>groundbreaking</strong> endeavor named
            the <strong>Student Information System (SIS) project</strong>. The
            SIS project is a <strong>holistic platform</strong> aimed at{" "}
            <strong>transforming university operations</strong>, catering to{" "}
            <strong>students, faculty, and administrators</strong>. This
            ambitious initiative encompasses{" "}
            <strong>
              enrollment, course management, program coordination, and data
              analysis
            </strong>
            .
          </p>

          <div className="seperator" />
          <h2>Empowering Students, Faculty, and Administration</h2>
          <div className="seperator" />
          <p>
            The SIS project envisages a{" "}
            <strong>seamless student experience</strong>, enabling{" "}
            <strong>enrollment, applications, and tuition tracking</strong>. For
            faculty, the system offers{" "}
            <strong>
              grade assignment, classroom visibility, and teaching management
            </strong>
            . Even{" "}
            <a href="https://en.wikipedia.org/wiki/Academic_administration">
              administrators{" "}
            </a>
            find tasks <strong>simplified</strong>, efficiently overseeing{" "}
            <strong>programs, cohorts, and administrative duties</strong>. These
            examples <strong>scratch the surface</strong> of the transformative
            power of the SIS project.
          </p>

          <div className="seperator" />
          <img
            src="blogs/web-developer-at-usj/web-developer-at-usj-01-f.png"
            alt="Student Enrollment"
          />

          <div className="seperator" />

          <h2>My Role as a Frontend Developer</h2>
          <div className="seperator" />
          <p>
            Being part of the SIS project has been a{" "}
            <strong>privileged learning experience</strong>. As a{" "}
            <strong>frontend developer</strong>, my focus is{" "}
            <strong>crafting intuitive user interfaces</strong>. Collaborating
            with <strong>developers and designers</strong>, my role is to ensure
            a functional,
            <a href="https://intellect.com/blog/7-features-of-user-friendly-software-solutions/#:~:text=User%2Dfriendly%20software%20means%20how,flow%20of%20the%20software%20design.">
              {" "}
              user-friendly platform
            </a>
            .
          </p>

          <div className="seperator" />

          <h2>Agile Methodology and Cutting-Edge Technology</h2>
          <div className="seperator" />
          <p>
            The SIS project follows <strong>agile methodology</strong>,{" "}
            <strong>adapting to changing requirements</strong>. This approach
            fosters a <strong>collaborative environment</strong>, with{" "}
            <strong>meetings and open communication</strong> for{" "}
            <strong>goal alignment</strong>.
          </p>

          <p>
            Technologically, SIS uses{" "}
            <a href="https://www.asp.net/">ASP.NET </a>for backend,{" "}
            <a href="https://angular.io/">Angular</a> for dynamic frontend, and{" "}
            <strong>Keycloak for secure access control</strong>. The project
            leverages <a href="https://www.elastic.co/kibana"> Kibana </a> and
            <a href="https://www.docker.com/"> Docker </a> for insights and{" "}
            <strong>efficient containerization</strong>.
          </p>

          <div className="seperator" />
          <img
            src="blogs/web-developer-at-usj/web-developer-at-usj-02-f.png"
            alt="Agile Methodology"
          />
          <div className="seperator" />

          <h2>Embracing New Horizons</h2>
          <div className="seperator" />
          <p>
            The journey as a{" "}
            <strong>frontend developer at USJ's SIS project</strong> has been{" "}
            <strong>remarkable</strong>, offering a{" "}
            <strong>wealth of experiences</strong>. While focusing on{" "}
            <strong>frontend</strong>, I'm <strong>eager to expand</strong> into
            backend and
            <a href="https://aws.amazon.com/devops/what-is-devops/#:~:text=DevOps%20is%20the%20combination%20of,development%20and%20infrastructure%20management%20processes.">
              {" "}
              DevOps
            </a>
            . The project <strong>ignites curiosity</strong> for{" "}
            <strong>comprehensive solutions</strong>.
          </p>
          <div className="seperator" />

          <h2>Conclusion</h2>
          <div className="seperator" />
          <p>
            The University of Saint Joseph's Student Information System project{" "}
            <strong>modernizes education administration</strong>. As a{" "}
            <strong>frontend developer</strong>, my role in this{" "}
            <strong>transformative journey</strong> is{" "}
            <strong>exhilarating</strong>. The SIS project{" "}
            <strong>resonates innovation</strong> in{" "}
            <strong>reshaping education</strong>. I contribute to a{" "}
            <strong>brighter future</strong> for{" "}
            <strong>students, faculty, and administrators</strong>.
          </p>

          <div className="seperator" />
          <button type="button" onClick={() => navigate("/#blogs")}>
            Go back
          </button>

          <div className="seperator" />
        </div>
      </div>
    </>
  );
}
export default WebDeveloperAtUSJ;
