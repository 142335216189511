import { FaNodeJs, FaReact } from "react-icons/fa";
import { AiOutlineConsoleSql, AiFillSchedule } from "react-icons/ai";
import {
  SiCss3,
  SiFirebase,
  SiHtml5,
  SiJavascript,
  SiNextdotjs,
  SiSass,
  SiThreedotjs,
  SiTypescript,
  SiWordpress,
  SiIonic,
  SiSolidity,
  SiWeb3Dotjs,
  SiGit,
  SiAngular,
  SiCapacitor,
  SiObsstudio,
  SiAdobephotoshop,
  SiCanva,
  SiAdobepremierepro,
  SiGooglesearchconsole,
} from "react-icons/si";
import { BiMicrophone } from "react-icons/bi";
import { BsBarChart } from "react-icons/bs";
export const allBlogs = [
  {
    id: 1,
    title: "Salon E Achrafieh: From Obscurity to Google's Top Rank",
    tags: ["Development", "Marketing", "Success Stories"],
    year: 2018,
    link: "/ranking-salon-e-achrafieh",
    imageURL: "blogs/covers/ranking-salon-e-achrafieh.jpg",
    description:
      "Witness firsthand how I leveraged my SEO expertise to propel Salon E Achrafieh to the coveted #1 spot on Google for keywords like 'Barbers in Beirut' and more",
  },
  {
    id: 2,
    title: "Devrabic: Arab Coders Unite",
    tags: ["Development", "Content Creation", "Success Stories"],
    year: 2023,
    link: "/devrabic-community",
    imageURL: "blogs/covers/devrabic-arabic-web-development-course.jpg",
    description:
      "Explore Devrabic's beginner-friendly Arabic web development course and community. Learn how I built this project from scratch",
  },
  {
    id: 3,
    title: "Shop Social Media Goods with SmLebanon",
    tags: ["Development"],
    year: 2022,
    link: "/shop-on-smlebanon",
    imageURL: "blogs/covers/shop-on-smlebanon.jpg",
    description:
      "SmLebanon: My store for all things social media - followers, likes, and more. Dive deep into its creation process",
  },
  {
    id: 4,
    title: "Cryptorabic: Empowering the Lebanese Crypto Community",
    tags: ["Development", "Content Creation"],
    year: 2022,
    link: "/cryptorabic-crypto-community",
    imageURL: "blogs/covers/cryptorabic-crypto-community.jpg",
    description:
      "Embark on an exciting journey with Cryptorabic, where we introduce you to Lebanon's vibrant crypto community, a thriving educational hub, and the promise of exhilarating NFT opportunities.",
  },
  {
    id: 5,
    title: "Innovative SIS Project at USJ",
    tags: ["Development"],
    year: 2023,
    link: "/web-developer-at-usj",
    imageURL: "blogs/covers/web-developer-at-usj.jpg",
    description:
      "Explore the intriguing project I'm currently employed on with the esteemed University of Saint Joseph's development team",
  },
  {
    id: 6,
    title: "Marketing Adventures: Account Manager at Kingsmen",
    tags: ["Marketing"],
    year: 2021,
    link: "/account-manager-at-kingsmen",
    imageURL: "blogs/covers/account-manager-at-kingsmen.jpg",
    description:
      "Embarked on a journey as an Account Manager, overseeing three prominent accounts in the UAE for over a year. Dive into this experience",
  },
  {
    id: 7,
    title: "Oracle Insights: My Experience as a Consultant at Widestreams",
    tags: ["Development"],
    year: 2021,
    link: "/oracle-consultant-at-widestreams",
    imageURL: "blogs/covers/oracle-consultant-at-widestreams.jpg",
    description:
      "Discover the expertise I've cultivated during my tenure as an Oracle consultant at Widestreams and more",
  },
  {
    id: 9,
    title: "S.M.S Security: Where NFTs Meet Surveillance",
    tags: ["Development"],
    year: 2022,
    link: "/s-m-s-security-nft-project",
    imageURL: "blogs/covers/s-m-s-security-nft-project.jpg",
    description:
      "S.M.S Security is an NFT project where I coded the smart contract and played a role in guiding and overseeing the project's art design",
  },
  {
    id: 10,
    title: "Bizaroob: Alchemizing Communities",
    tags: ["Marketing", "Content Creation"],
    year: 2020,
    link: "/community-manager-at-bizaroob",
    imageURL: "blogs/covers/community-manager-at-bizaroob.jpg",
    description:
      "Served as a Community Manager for one of Lebanon's premier coffee-related retailers, overseeing the community and contributing to content creation",
  },
  {
    id: 11,
    title: "Unleashing Affiliate Marketing Success with OGAds",
    tags: ["Marketing", "Success Stories"],
    year: 2019,
    link: "/ogads-affiliate-marketing-journey",
    imageURL: "blogs/covers/ogads-affiliate-marketing-journey.jpg",
    description:
      "Explore one of my most successful online income streams—affiliate marketing with the OGAds network—and uncover the impressive results I achieved",
  },
  {
    id: 12,
    title: "The Pioneer of Thug Life Gaming Compilations",
    tags: ["Content Creation", "Success Stories"],
    year: 2015,
    link: "/thug-life-gaming-compilations-pioneer",
    imageURL: "blogs/covers/thug-life-gaming-compilations-pioneer.jpg",
    description:
      "One of my earliest triumphs was pioneering the integration of the 'Thug Life' meme into gaming, creating the first-ever gaming 'Thug Life' compilations on YouTube. Dive into this incredible journey",
  },
  {
    id: 13,
    title: "Bad Mike: The young dream",
    tags: ["Content Creation"],
    year: 2015,
    link: "/bad-mike-youtube-channel",
    imageURL: "blogs/covers/bad-mike-youtube-channel.jpg",
    description:
      "Embarking on a youthful dream yet to be fully realized—the birth of my YouTube channel. I dedicated over five years to crafting content in pursuit of YouTube stardom",
  },
  {
    id: 14,
    title: "Instagram Gaming Pages: Fame and Income",
    tags: ["Content Creation", "Marketing", "Success Stories"],
    year: 2018,
    link: "/instagram-gaming-pages",
    imageURL: "blogs/covers/instagram-gaming-pages.jpg",
    description:
      "I turned Instagram into my playing field, nurturing and growing over three pages into significant followings. This strategic move opened up multiple income streams through promotions and various methods",
  },
  {
    id: 15,
    title: "Making Money Online",
    tags: ["General"],
    year: 2023,
    link: "/make-money-online-in-lebanon",
    imageURL: "blogs/covers/make-money-online-in-lebanon.jpeg",
    description:
      "Are you a Lebanese that is just bored of going to your same old job. Are you wishing that maybe, just maybe, you could make money at home in Lebanon?",
  },
  {
    id: 16,
    title: "Paying school fees without any support",
    tags: ["Success Stories"],
    year: 2020,
    link: "/my-journey",
    imageURL: "blogs/covers/my-journey.jpeg",
    description:
      "Learn more about Mike Majdalani’s journey in this article. How he grew to be from the few Lebanese who succeeded in building a passive income online",
  },
  {
    id: 17,
    title: "YouTubers in Lebanon",
    tags: ["General"],
    year: 2020,
    link: "/youtubers-in-lebanon",
    imageURL: "blogs/covers/youtubers-in-lebanon.jpg",
    description:
      "Have you ever wondered who the top YouTubers are? And maybe you’re wondering how to be a YouTuber in Lebanon as well",
  },
  {
    id: 18,
    title: "Entrepreneurs in Lebanon",
    tags: ["General"],
    year: 2021,
    link: "/entrepreneurs-in-lebanon",
    imageURL: "blogs/covers/entrepreneurs-in-lebanon.jpeg",
    description:
      "Have you ever wondered what business in Lebanon can be done online, and can increase profits for its users?",
  },

  // Add more blogs with tags and year as needed
];

export const technologySkills = [
  {
    icon: <SiHtml5 />,
    text: "HTML",
    color: "rgb(240, 101, 41)",
  },
  {
    icon: <SiCss3 />,
    text: "CSS",
    color: "rgb(41, 101, 241)",
  },
  {
    icon: <SiJavascript />,
    text: "Javascript",
    color: "rgba(218, 215, 0)",
  },
  {
    icon: <SiTypescript />,
    text: "TypeScript",
    color: "rgb(45, 121, 199)",
  },
  {
    icon: <FaReact />,
    text: "ReactJs",
    color: "rgb(124, 197, 217)",
  },
  {
    icon: <SiAngular />,
    text: "AngularJs",
    color: "rgba(255, 16, 16)",
  },
  {
    icon: <SiNextdotjs />,
    text: "NextJs",
    color: "rgb(245, 245, 245)",
  },
  {
    icon: <SiIonic />,
    text: "Ionic",
    color: "rgba(0, 88, 255, 0.8)",
  },
  {
    icon: <SiFirebase />,
    text: "Firebase",
    color: "rgb(246,130,13)",
  },
  {
    icon: <SiSass />,
    text: "Sass",
    color: "rgb(204, 102, 153)",
  },
  {
    icon: <SiGit />,
    text: "Git",
    color: "rgba(255, 66, 0, 0.8)",
  },
  {
    icon: <SiThreedotjs />,
    text: "Three.js",
    color: "rgb(255, 255, 255)",
  },
  {
    icon: <SiWeb3Dotjs />,
    text: "Web3.js",
    color: "rgba(255, 0, 50, 0.8)",
  },
  {
    icon: <SiSolidity />,
    text: "Solidity",
    color: "rgba(162, 162, 162, 0.8)",
  },
  {
    icon: <SiCapacitor />,
    text: "Capacitor",
    color: "rgba(0, 210, 255, 0.8)",
  },
  // {
  //   icon: <SiMongodb />,
  //   text: "MongoDB",
  //   color: "rgb(74, 167, 74)",
  // },
  {
    icon: <FaNodeJs />,
    text: "Node.js",
    color: "rgb(104, 160, 99)",
  },
  {
    icon: <AiOutlineConsoleSql />,
    text: "SQL",
    color: "rgba(138, 138, 138)",
  },

  {
    icon: <SiWordpress />,
    text: "Wordpress",
    color: "rgb(33, 117, 155)",
  },
];

export const contentcreationSkills = [
  {
    icon: <SiObsstudio />,
    text: "OBS Studio",
    color: "rgb(124, 125, 125)",
  },

  {
    icon: <SiAdobephotoshop />,
    text: "Photoshop",
    color: "rgb(48, 167, 254)",
  },

  {
    icon: <SiAdobepremierepro />,
    text: "Premiere",
    color: "rgb(149, 149, 251)",
  },
  {
    icon: <SiCanva />,
    text: "Canva",
    color: "rgb(52, 232, 235)",
  },
  {
    icon: <SiGooglesearchconsole />,
    text: "SEO",
    color: "rgb(122, 182, 255)",
  },
  {
    icon: <BiMicrophone />,
    text: "Speaking",
    color: "rgb(227, 39, 170)",
  },
  {
    icon: <AiFillSchedule />,
    text: "Management",
    color: "rgb(198, 237, 57)",
  },
  {
    icon: <BsBarChart />,
    text: "Marketing",
    color: "rgb(237, 64, 81)",
  },
];
