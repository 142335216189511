import {
  FaInstagram,
  FaLinkedin,
  FaDiscord,
  FaTelegram,
  FaSkype,
  FaTwitter,
} from "react-icons/fa";
import ContactForm from "../components/contact-form/contact-form";
import { MdEmail } from "react-icons/md";
function ContactSection() {
  return (
    <div className="contact-section">
      <h1>Contact me</h1>
      <h3>
        Don't hesitate to send me a message if you'd like us to work together.
        You never know what opportunities may await!
      </h3>
      <div className="contact-section__socials">
        <a href="mailto:mikemajdalani1@gmail.com	">
          {" "}
          <MdEmail />{" "}
        </a>

        <a href="skype:bad_mike_?chat">
          <FaSkype />
        </a>
        <a href="https://discord.com/users/mikemajdalani">
          <FaDiscord />
        </a>
        <a href="https://t.me/@mikemajdalani">
          <FaTelegram />
        </a>
      </div>
      {/* <h3>Resume </h3>
      <a
        className="button"
        href=""
        target="_blank"
        rel="noopener noreferrer"
      >
        View
      </a> */}
      <h3>Social Media </h3>
      <div className="contact-section__socials">
        <a href="https://instagram.com/mikemajdalani">
          {" "}
          <FaInstagram />{" "}
        </a>
        <a href="https://www.linkedin.com/in/mikemajdalani/">
          <FaLinkedin />
        </a>
        <a href="https://twitter.com/bad_mike">
          <FaTwitter />
        </a>
      </div>
      <ContactForm />
    </div>
  );
}
export default ContactSection;
