import "./logocards.scss";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useState } from "react";
function Logocards({ picture, href, color }) {
  const [loaded, setLoaded] = useState(false);
  return (
    <div className="logoCard" style={{ backgroundColor: color }}>
      <a href={href}>
        {loaded ? null : (
          <Skeleton className="skeletonLogoCards" baseColor={color} />
        )}
        <img
          className="logoImage"
          alt="project"
          src={picture}
          style={{ opacity: loaded ? 1 : 0 }}
          onLoad={() => {
            setLoaded(true);
          }}
        ></img>
      </a>
    </div>
  );
}
export default Logocards;
