import { useNavigate } from "react-router-dom";

import Helmet from "react-helmet";

function MyJourney() {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>My Journey - Mike Majdalani</title>
        <meta
          name="description"
          content="Welcome to Mike Majdalani&#039;s official website, here you can learn about my journey, how i make money online in Lebanon, and much more!"
        />
        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9202844998762658"
          crossOrigin="anonymous"
        ></script>
        <link rel="canonical" href="https://mikemajdalani.com/my-journey/" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="My Journey - Mike Majdalani" />
        <meta
          property="og:description"
          content="Welcome to Mike Majdalani&#039;s official website, here you can learn about my journey, how i make money online in Lebanon, and much more!"
        />
        <meta
          property="og:url"
          content="https://mikemajdalani.com/my-journey/"
        />
        <meta property="og:site_name" content="Mike Majdalani" />
      </Helmet>

      <div className="mainContainer">
        <div className="blogcontainer">
          <h1>My Journey</h1>
          <h3 style={{ textAlign: "center" }}>
            Written by the amazing{" "}
            <a href="https://www.instagram.com/sarahxaoun/">Sarah Aoun. </a>
          </h3>
          <div className="seperator" />
          <div className="seperator" />
          <p>
            <b>Mike Majdalani</b> , was a student pursuing a bachelor’s degree
            in computer science at{" "}
            <a href="https://www.usj.edu.lb/universite/institutions.php?getinst=26">
              USJ’s INCI
            </a>
            , and paid his license fees without any support from his parents.
            <br /> <br />
            It all started in <b>2017</b>, when Mike’s parents insisted that
            their son receive a top-notch education at a private university,{" "}
            <a href="https://www.usj.edu.lb/">USJ.</a> <br /> <br /> Despite the
            financial problems, they hoped to receive help from the social
            service to be able to pay for their son’s education, which amounted
            to around
            <b> $15,000 a year.</b> <br /> <br /> In the first year, not being
            able to receive help from the social service despite several
            attempts, Mike’s parents went into debt to pay for his first year
            school fees. <br /> <br />
            Being a{" "}
            <a href="https://en.wikipedia.org/wiki/YouTuber">Youtuber</a> , Mike
            uploaded in <b> November 2018</b> a video about the game{" "}
            <a href="https://www.epicgames.com/fortnite/en-US/home">
              “Fortnite”{" "}
            </a>
            on Youtube. To his surprise, his video gained popularity and gained
            over <b>1.2 million views</b>, which made him one of the most
            popular gaming
            <a href="https://mikemajdalani.com/youtubers-in-lebanon">
              {" "}
              YouTubers in Lebanon.
            </a>
          </p>
          <div className="seperator" />
          <p>
            He continued his videos on Youtube and at the same time, he became
            active on <b>Instagram</b> by creating a very large{" "}
            <a href="https://www.adamenfroy.com/how-to-make-money-on-instagram">
              network of Instagram accounts
            </a>{" "}
            with more than 50,000 followers. His online work has been growing
            and expanding more and more as he went along and started earning{" "}
            <b>$7,584.4 in one month</b> with the assistance of a popular
            affiliate marketing network called{" "}
            <a href="https://ogads.com/">OGads</a>.
            <br /> <br />
            Thanks to the social networks he manages, Mike managed to pay the
            cost of his license <b>entirely on his own</b>. This qualified him
            to become a successful{" "}
            <a href="https://mikemajdalani.com/entrepreneurs-in-lebanon">
              digital entrepreneur in Lebanon
            </a>
            .
          </p>
          <div className="seperator" />
          <button type="button">
            <a href="https://www.usj.edu.lb/news.php?id=9750">
              Check Full blog on USJ website
            </a>
          </button>
          <div className="seperator" />
          <img
            src="/blogs/my-journey/my-journey-bills-01.jpg"
            alt="Mike Majdalani Journey"
          />
          <div className="seperator" />
          <div className="seperator" />
          <h2>and the journey continues…</h2>
          <div className="seperator" />
          <div className="twocolumns">
            <p>
              We note that he intended to continue his <b>master’s studies,</b>{" "}
              but the current{" "}
              <a href="https://www.worldbank.org/en/news/press-release/2021/05/01/lebanon-sinking-into-one-of-the-most-severe-global-crises-episodes">
                economic situation
              </a>{" "}
              in Lebanon became an obstacle for him. <br /> <br />
              Despite the motivating and exciting side of the story, many
              difficulties accompanied him throughout his journey. <br /> <br />
              <b>Earning</b> so much money and the ability of{" "}
              <a href="https://mikemajdalani.com/make-money-online-in-lebanon">
                making money online in Lebanon
              </a>{" "}
              at a very young age forced him to become responsible, he had to
              open a bank account and learn how to receive and send money, in
              addition to his university studies which required enough{" "}
              <b>time and of effort</b>.
            </p>

            <p>
              Mike would spend whole nights awake, especially since{" "}
              <a href="https://www.bayt.com/en/lebanon/jobs/online-jobs/">
                working online
              </a>{" "}
              alone requires so much time and effort.
              <br /> <br /> He was never sure that he would be able to have
              enough money to pay for his semester credits. This phase was full
              of physical fatigue and
              <a href="https://www.psychiatry.org/patients-families/depression/what-is-depression">
                {" "}
                mental depression
              </a>{" "}
              without anyone’s support. <br /> <br />
              As sad, tiring and depressing as this period was – especially as a
              young man earning his own <b>money online in Lebanon</b> he could
              not spend it for his own pleasure or his own comfort – he knew he
              had no choice and that he had to continue at all costs in order to
              finish his studies.
            </p>
          </div>
          <div className="seperator" />
          <div className="seperator" />
          <img
            src="/blogs/covers/my-journey.jpeg"
            alt="Mike Majdalani Journey"
          />
          <div className="seperator" />
          <div className="seperator" />
          <q>
            We have to try a million things to be able to be successful, nothing
            is easy, being motivated and working without ever giving up,
            stepping out of our comfort zone and giving everything we can,
            brings us closer and closer to our goal and we will surely reach it
          </q>{" "}
          <i>- Mike Majdalani</i>
          <div className="seperator" />
          <button type="button" onClick={() => navigate("/#blogs")}>
            Go back
          </button>
          <div className="seperator" />
        </div>
      </div>
    </>
  );
}
export default MyJourney;
