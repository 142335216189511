import { useNavigate } from "react-router-dom";
import Helmet from "react-helmet";

function BadMikeYouTubeChannel() {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>Bad Mike: The young dream</title>
        <meta
          name="description"
          content="Embarking on a youthful dream yet to be fully realized—the birth of my YouTube channel. I dedicated over five years to crafting content in pursuit of YouTube stardom"
        />
        <link
          rel="canonical"
          href="https://mikemajdalani.com/bad-mike-youtube-channel/"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Bad Mike: The young dream" />
        <meta
          property="og:description"
          content="Embarking on a youthful dream yet to be fully realized—the birth of my YouTube channel. I dedicated over five years to crafting content in pursuit of YouTube stardom"
        />
        <meta
          property="og:url"
          content="https://mikemajdalani.com/bad-mike-youtube-channel/"
        />
        <meta property="og:site_name" content="Mike Majdalani" />
      </Helmet>
      <div className="mainContainer">
        <div className="blogcontainer">
          <h1>Bad Mike: The young dream</h1>
          <div className="seperator" />
          <p>
            In 2014, at the ripe age of 15, I embarked on a thrilling adventure
            into the world of YouTube. My dream was simple yet grand: to become
            a YouTube sensation, just like the iconic{" "}
            <a href="https://en.wikipedia.org/wiki/PewDiePie">PewDiePie</a> and
            other internet celebrities. I named my channel "
            <strong>Bad Mike</strong>," a moniker that originated from my old{" "}
            <a href="https://www.playstation.com/en-us/playstation-network/">
              PSN
            </a>{" "}
            name, "<strong>Bad Agent 47</strong>," paying homage to my love for
            the <strong>Hitman</strong> series' enigmatic{" "}
            <a href="https://www.imdb.com/title/tt2679042/">Agent 47</a>. Thus,{" "}
            <strong>Bad Mike</strong> was born, and it was destined to be all
            about gaming, as I was an ardent gamer in my youth.
          </p>
          <div className="seperator" />
          <h2>The Inception</h2>
          <div className="seperator" />
          <p>
            My YouTube journey officially began on{" "}
            <strong>August 31, 2014</strong>, with the upload of my very first
            video, a hilarious{" "}
            <a href="https://www.ea.com/games/battlefield/battlefield-hardline">
              Battlefield Hardline
            </a>{" "}
            gameplay clip edited using{" "}
            <a href="https://www.microsoft.com/store/apps/9mvfq4lmz6c9">
              Windows Movie Maker
            </a>
            . Little did I know that this moment would mark the beginning of a
            long and thrilling journey through the world of online content
            creation.
          </p>{" "}
          <div className="seperator" />
          <img
            src="blogs/bad-mike-youtube-channel/bad-mike-youtube-channel-01.jpg"
            alt="Bad Mike: The young dream"
          />
          <div className="seperator" />
          <div className="seperator" />
          <h2>Stats</h2>
          <div className="seperator" />
          <p>
            Fast forward to the present, and <strong>Bad Mike</strong> has
            reached impressive heights, boasting over{" "}
            <strong>29,000 subscribers</strong>, <strong>6,804,800</strong>{" "}
            views across <strong>460 videos</strong>, and generating a
            five-figure income from{" "}
            <a href="https://www.creditkarma.com/income/i/how-much-do-youtubers-make#:~:text=YouTubers%20make%20an%20average%20of,of%20views%20your%20video%20receives">
              ad revenue
            </a>{" "}
            alone. As of <strong>September 4, 2023</strong>, I currently have{" "}
            <strong>27,500</strong> dedicated subscribers.
          </p>
          <div className="seperator" />
          <h2>Cracking the Top 100 in Lebanon</h2>
          <div className="seperator" />
          <p>
            One of the highlights of my YouTube journey came in 2017 when I
            achieved a significant milestone. I was ranked <strong>37th</strong>{" "}
            among the top <strong>100 YouTubers in Lebanon</strong> according to
            the{" "}
            <a href="https://socialblade.com/youtube/top/country/lb">
              Social Blade
            </a>{" "}
            rankings. This accomplishment was particularly sweet, as I had
            surpassed well-known figures like{" "}
            <a href="https://ar.wikipedia.org/wiki/%D9%88%D8%A7%D8%A6%D9%84_%D9%83%D9%81%D9%88%D8%B1%D9%8A">
              Wael Kfoury
            </a>{" "}
            and <a href="https://www.instagram.com/maritta/">Maritta Hallani</a>
            .
          </p>
          <div className="seperator" />
          <div className="seperator" />
          <iframe
            className="video"
            src="https://www.youtube.com/embed/c0TvK2z4_U0?si=-yeyJ0Ta4TCJPZBI"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
          <div className="seperator" />
          <h2>The Series that Defined Bad Mike</h2>
          <div className="seperator" />
          <h3>
            Throughout my YouTube journey, several series played a pivotal role
            in defining the content on my channel:
          </h3>
          <div className="seperator" />
          <p>
            {" "}
            <strong>GTA Thug Life Compilations (2015-2017):</strong> This
            series, which brought the concept of "<strong>thug life</strong>"
            into the gaming world, played a crucial role in skyrocketing my
            channel's popularity. It even inspired a full blog post on my
            website{" "}
            <a href="https://mikemajdalani.com/thug-life-gaming-compilations-pioneer">
              here
            </a>
            .
          </p>
          <div className="seperator" />
          <iframe
            className="video"
            src="https://www.youtube.com/embed/videoseries?si=zlB6Ze-Tj259_0er&amp;list=PL2Yab9oR2roVrudRmsyjzKwAlvgnUTefH"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
          <div className="seperator" />
          <p>
            <strong>GTA Movies (2016-2017):</strong> One of the most creatively
            fulfilling series I ever created on my channel, these{" "}
            <strong>GTA</strong> movies leveraged the{" "}
            <a href="https://www.redbull.com/int-en/rockstar-editor-on-console-how-to-make-gta-movies">
              Rockstar Editor
            </a>{" "}
            tool to craft cinematic experiences. The countless hours I spent
            editing these videos were truly rewarding.
          </p>
          <div className="seperator" />
          <iframe
            className="video"
            src="https://www.youtube.com/embed/videoseries?si=hvr861YucS1m19sF&amp;list=PL2Yab9oR2roUPEORgCJXUw2e1ZXTcDfoC"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
          <div className="seperator" />
          <p>
            <strong>More Thug Life Videos (2017-2019):</strong> The{" "}
            <strong>thug life</strong> trend on my channel continued, expanding
            to include games like{" "}
            <a href="https://www.ea.com/games/battlefield/battlefield-1">
              Battlefield 1
            </a>
            ,{" "}
            <a href="https://www.ubisoft.com/en-gb/game/for-honor">For Honor</a>
            , <strong>Fortnite</strong>, and various other gaming content that
            resonated with my audience.
          </p>
          <div className="seperator" />
          <p>
            <strong>Fortnite (2018-2019):</strong> With the rise of{" "}
            <strong>Fortnite</strong>'s popularity, I joined the craze and began
            uploading{" "}
            <a href="https://www.fortnite.com/?lang=en-US">Fortnite</a> content
            and gameplay. One video even reached a staggering{" "}
            <strong>1.3 million views</strong>. This series marked a shift
            towards showing my face more on camera and engaging with my viewers
            through live gameplay.
          </p>
          <div className="seperator" />
          <iframe
            className="video"
            src="https://www.youtube.com/embed/videoseries?si=1_9LYFJ2HUq44IIj&amp;list=PL2Yab9oR2roWKQn9IBJqi6aU0h9CckI9N"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
          <div className="seperator" />
          <p>
            <strong>Diverse Gaming Series (2018-2019):</strong> After{" "}
            <strong>Fortnite</strong>, I diversified my content by creating
            around <strong>20 videos each</strong> for games like{" "}
            <a href="https://www.rockstargames.com/reddeadredemption2/">
              Red Dead Redemption 2
            </a>
            ,{" "}
            <a href="https://www.callofduty.com/warzone">
              Call of Duty Warzone
            </a>
            , and <a href="https://www.fallguys.com/">Fall Guys</a>.
            <div className="seperator" />
            <strong>Age of Mythology (2020-2021):</strong> A game that held a
            special place in my heart,{" "}
            <a href="https://www.ageofempires.com/games/aom/">
              Age of Mythology
            </a>
            , became the primary focus of my channel during this period. I
            decided to switch to <strong>Age of Mythology</strong> content as
            the other series lost momentum. This transition not only
            reinvigorated my channel but also formed a tight-knit community of
            fans who shared my passion for the game.
          </p>
          <div className="seperator" />
          <iframe
            className="video"
            src="https://www.youtube.com/embed/videoseries?si=ZQ-tyNO5EeplRbJ5&amp;list=PL2Yab9oR2roUoVZhN7qF2FOl3vu0U1VEK"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
          <div className="seperator" />
          <h2>Turning Point</h2>
          <div className="seperator" />
          <p>
            As life marched on, I found myself entangled in various other
            responsibilities, including{" "}
            <a href="https://mikemajdalani.com/my-journey">university</a>, work,
            and personal ventures, which took precedence over my YouTube
            channel. Consequently, I ceased uploading content on{" "}
            <strong>Bad Mike</strong> after 2021.
          </p>
          <div className="seperator" />
          <h2>A Precious Journey</h2>
          <div className="seperator" />
          <p>
            <strong>Bad Mike</strong> is more than just a YouTube channel to me;
            it represents six to seven years of relentless effort, boundless
            passion, and unwavering dedication. While I may not have achieved
            the grand milestones I initially aspired to, I carry no regrets.
            Mistakes were made, such as frequent content shifts that led to
            audience departures, but they were part of my learning process. Life
            threw <strong>challenges</strong> my way, including financial
            responsibilities, further slowing my channel's growth.
          </p>
          <div className="seperator" />
          <p>
            The dream of becoming a YouTube sensation may have evolved over the
            years, but the journey remains an indelible part of my life.
            Although I may not see the dream fully realized, I hold on to the
            hope that circumstances may change, allowing me to return to my
            gaming roots and continue sharing my passion with the world.{" "}
            <strong>Bad Mike</strong>'s story is a testament to growth,
            resilience, and the enduring spirit of a young dreamer.
          </p>
          <div className="seperator" />
          <button type="button" onClick={() => navigate("/#blogs")}>
            Go back
          </button>
          <div className="seperator" />
        </div>
      </div>
    </>
  );
}
export default BadMikeYouTubeChannel;
