import { useNavigate } from "react-router-dom";
function NotFound() {
  const navigate = useNavigate();
  return (
    <div className="container_notFound_items">
      <h1>Oops! You seem to be lost.</h1>

      <button type="button" onClick={() => navigate("/")}>
        Go back Home
      </button>
    </div>
  );
}
export default NotFound;
