import SkillCard from "../components/SkillCard/skillcards";
import Logocards from "../components/logocards/logocards";
import { technologySkills, contentcreationSkills } from "../utils/content";
import { BsGithub } from "react-icons/bs";
import { FaGoogleDrive } from "react-icons/fa";
function ServicesSection() {
  return (
    <div className="services-container">
      <div className="services-container__section">
        <h1>Development</h1>
        <h3>
          As a developer, I bring a wealth of expertise to your projects. With a
          deep-rooted passion for development, I don't just write code; I craft
          solutions that empower businesses and individuals.
        </h3>
        <div className="services-container__github">
          <h3>Github:</h3>
          <a href="https://github.com/MikeMajdalani1">
            <BsGithub style={{ fontSize: "40px" }} />
          </a>
        </div>
        <div />
        <h2>Skill Stack</h2>
        <div className="services-container__skill-stack">
          {technologySkills.map((item, index) => (
            <SkillCard
              key={index}
              text={item.text}
              color={item.color}
              icon={item.icon}
            ></SkillCard>
          ))}
        </div>
        <h2>Top Projects</h2>
        <div className="services-container__projects">
          <Logocards
            href="https://devrabic.com"
            picture="logos/devrabic.png"
            color="#3a4042"
          />
          <Logocards
            href="https://cryptorabic.com"
            picture="logos/cryptorabic.png"
            color="#1a3b51"
          />
          <Logocards
            href="https://smlebanon.com"
            picture="logos/smlebanon.png"
            color="#5300b0"
          />
          <Logocards
            href="https://polygonscan.com/address/0xC65fB61Bdcc3a0a93Cf00b395BbA3361122FBfc2#code"
            picture="logos/smssecurity.png"
            color="#2c6761"
          />
        </div>
      </div>
      <div className="services-container__section">
        <h1>Content Creation</h1>
        <h3>
          I'm a content creator with a passion for crafting compelling
          narratives. I specialize in producing content that empowers businesses
          and individuals through engaging storytelling.
        </h3>
        <h3>
          My content creation skills encompass recording any video content,
          editing videos using video editing software, creating any necessary
          visuals, and implementing the best SEO strategies when posting the
          content.
        </h3>
        {/* <div className="services-container__github">
          <h3>Portfolio:</h3>
          <a href="https://drive.google.com/drive/folders/13LW7SIVB65bwlyLLqeWgmRajMXo7akH6?usp=sharing">
            <FaGoogleDrive style={{ fontSize: "40px" }} />
          </a>
        </div> */}
        <h2>Skill Stack</h2>
        <div className="services-container__skill-stack">
          {contentcreationSkills.map((item, index) => (
            <SkillCard
              key={index}
              text={item.text}
              color={item.color}
              icon={item.icon}
            ></SkillCard>
          ))}
        </div>
        <h2>Top Pages</h2>
        <div className="services-container__projects">
          <Logocards
            href="https://instagram.com/devrabic_"
            picture="logos/devrabic.png"
            color="#3a4042"
          />
          <Logocards
            href="https://www.youtube.com/c/BadMike47"
            picture="logos/badmike.png"
            color="#120203"
          />
          <Logocards
            href="https://www.youtube.com/@mikemajdalani"
            picture="logos/minimike.jpg"
            color="#151314"
          />
        </div>
      </div>
    </div>
  );
}
export default ServicesSection;
