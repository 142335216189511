import { useNavigate } from "react-router-dom";

import Helmet from "react-helmet";

function OracleConsultantAtWidestreams() {
  const navigate = useNavigate();
  return (
    <>
      <Helmet>
        <title>
          Oracle Insights: My Experience as a Consultant at Widestreams
        </title>
        <meta
          name="description"
          content="Discover the expertise I've cultivated during my tenure as an Oracle consultant at Widestreams S.A.R.L"
        />
        <link
          rel="canonical"
          href="https://mikemajdalani.com/oracle-consultant-at-widestreams/"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Oracle Insights: My Experience as a Consultant at Widestreams"
        />
        <meta
          property="og:description"
          content="Discover the expertise I've cultivated during my tenure as an Oracle consultant at Widestreams S.A.R.L"
        />
        <meta
          property="og:url"
          content="https://mikemajdalani.com/oracle-consultant-at-widestreams/"
        />
        <meta property="og:site_name" content="Mike Majdalani" />
      </Helmet>
      <div className="mainContainer">
        <div className="blogcontainer">
          <h1>Oracle Insights: My Experience as a Consultant at Widestreams</h1>
          <div className="seperator" />
          <p>
            Working as an <strong>Oracle Technical Consultant</strong> at{" "}
            <a href="http://widestreams.com/">Widestreams S.A.R.L.</a>, a
            leading business and technology consulting company specializing in
            management,{" "}
            <a href="https://www.techtarget.com/searchcio/definition/e-business">
              e-business
            </a>{" "}
            , <a href="https://www.oracle.com/erp/what-is-erp/">ERP</a>, and{" "}
            <a href="https://www.suse.com/suse-defines/definition/cloud-solutions/">
              Cloud solutions
            </a>
            , has been an enriching and transformative experience. In this blog,
            I'll delve into the intricate details of my role and
            responsibilities, highlighting how I contributed to the development
            of <strong>Oracle</strong> solutions and fostered integration
            between systems.
          </p>

          <div className="seperator" />

          <img
            src="blogs/oracle-consultant-at-widestreams/oracle-consultant-at-widestreams-01-f.png"
            alt="Oracle Insights: My Experience as a Consultant at Widestreams"
          />

          <div className="seperator" />

          <div className="seperator" />
          <h3>
            Development of Oracle Reports using Oracle OBIEE and Oracle Reports
            Builder
          </h3>
          <div className="seperator" />
          <p>
            One of my primary responsibilities at Widestreams was the
            development of{" "}
            <a href="https://docs.oracle.com/middleware/11119/classic/use-reports/pbr_intro001.htm">
              Oracle Reports
            </a>
            . This task entailed leveraging <strong>Oracle OBIEE</strong> and{" "}
            <strong>Oracle Reports Builder</strong> to create comprehensive and
            insightful reports. These reports played a pivotal role in providing
            valuable data-driven insights to our clients, enabling them to make
            informed decisions.
          </p>
          <div className="seperator" />
          <p>
            <a href="https://www.oracle.com/business-analytics/business-intelligence/technologies/bi-enterprise-edition.html">
              Oracle OBIEE
            </a>
            , or{" "}
            <strong>Oracle Business Intelligence Enterprise Edition</strong>,
            was instrumental in crafting interactive and visually appealing
            reports. It allowed me to design highly customizable dashboards and
            interactive reports that presented data in a user-friendly manner.
            These reports often served as a critical tool for our clients,
            enabling them to monitor key performance indicators and gain
            actionable insights into their businesses.
          </p>
          <div className="seperator" />
          <p>
            Furthermore, <strong>Oracle Reports Builder</strong> empowered me to
            design and generate sophisticated, pixel-perfect reports. Whether it
            was financial statements, inventory reports, or sales analytics, I
            had the opportunity to transform raw data into concise and
            easy-to-understand{" "}
            <a href="https://holowczak.com/oracle9i-developer-suite-forms-and-reports-tutorial/18/">
              reports
            </a>{" "}
            that greatly benefited our clients.
          </p>

          <div className="seperator" />
          <div className="seperator" />
          <img
            src="blogs/oracle-consultant-at-widestreams/oracle-consultant-at-widestreams-02.jpg"
            alt="Oracle Insights: My Experience as a Consultant at Widestreams"
          />

          <div className="seperator" />
          <div className="seperator" />

          <h3>Development of Oracle Forms using Oracle Forms Builder</h3>
          <div className="seperator" />
          <p>
            In addition to <strong>Oracle Reports</strong>, I also played a
            significant role in the development of <strong>Oracle Forms</strong>
            . Using{" "}
            <a href="https://www.oracle.com/application-development/technologies/forms/forms.html">
              Oracle Forms Builder
            </a>
            , I contributed to the creation of intuitive and user-friendly
            interfaces for our clients' applications.{" "}
            <strong>Oracle Forms</strong> served as the backbone of various
            business processes, ensuring efficient data entry, validation, and
            processing.
          </p>
          <div className="seperator" />
          <p>
            Through <strong>Oracle Forms</strong>, we were able to design and
            develop custom applications tailored to our clients' specific needs.
            These applications facilitated tasks such as order management,
            employee management, and customer relationship management. The
            seamless integration of{" "}
            <a href="https://www.unf.edu/its/knowledgebase/banner/procedure-manual/7-Oracle-Forms.html#:~:text=Oracle%20Forms%2C%20a%20component%20of,presents%20and%20manipulates%20the%20data.">
              Oracle Forms
            </a>{" "}
            into their existing systems streamlined operations and enhanced
            productivity.
          </p>

          <div className="seperator" />
          <div className="seperator" />
          <img
            src="blogs/oracle-consultant-at-widestreams/oracle-consultant-at-widestreams-03.jpg"
            alt="Oracle Insights: My Experience as a Consultant at Widestreams"
          />
          <div className="seperator" />
          <div className="seperator" />

          <h3>
            Development of APIs using PL/SQL procedures for integration between
            Oracle EBS and Legacy Systems
          </h3>
          <div className="seperator" />
          <p>
            Another critical aspect of my role at Widestreams was the
            development of{" "}
            <a href="https://docs.oracle.com/cd/E14571_01/integration.1111/e10537/T430238T430243.htm#:~:text=Adapter%20for%20Oracle%20Applications%20uses,SQL%20APIs%20to%20retrieve%20data.">
              APIs{" "}
            </a>
            using <strong>PL/SQL procedures</strong>. These APIs were
            instrumental in bridging the gap between{" "}
            <strong>Oracle E-Business Suite</strong>{" "}
            <a href="https://www.oracle.com/applications/ebusiness/">(EBS)</a>{" "}
            and legacy systems. Integration is a fundamental requirement for
            modern businesses, and our clients relied on us to facilitate the
            smooth flow of data between their different systems.
          </p>
          <div className="seperator" />
          <p>
            <a href="https://www.ibm.com/docs/en/db2/11.5?topic=support-procedures-plsql#:~:text=PL%2FSQL%20procedures%20are%20database,PL%2FSQL%20CREATE%20PROCEDURE%20statement.">
              PL/SQL procedures
            </a>{" "}
            allowed me to create robust and efficient APIs that facilitated data
            synchronization, reducing manual data entry and minimizing errors.
            Whether it was syncing customer information, order data, or
            inventory levels, these <strong>APIs</strong>
            played a <strong>crucial role</strong> in ensuring data consistency
            and accuracy across systems.
          </p>

          <div className="seperator" />
          <div className="seperator" />
          <img
            src="blogs/oracle-consultant-at-widestreams/oracle-consultant-at-widestreams-04-f.png"
            alt="Oracle Insights: My Experience as a Consultant at Widestreams"
          />
          <div className="seperator" />
          <div className="seperator" />

          <p>
            In conclusion, my tenure at Widestreams as an{" "}
            <a href="https://www.lamprell.com/~/media/files/l/lamprell-v3/storage/docs/august%202014/job%20description%20-%20oracle%20technical%20consultant.pdf">
              Oracle Technical Consultant
            </a>{" "}
            was a period filled with significant development experiences,
            particularly in the backend realm. Through my work with{" "}
            <strong>Oracle Reports</strong>, <strong>Oracle Forms</strong>, and{" "}
            <strong>PL/SQL procedures</strong>, I contributed to the growth and
            success of our clients by providing them with powerful solutions for
            their data management and integration needs. It was a journey of
            continuous learning and innovation, and I am grateful for the
            opportunity to have been a part of the Widestreams team, helping
            businesses thrive through technology.
          </p>
          <div className="seperator" />
          <button type="button" onClick={() => navigate("/#blogs")}>
            Go back
          </button>

          <div className="seperator" />
        </div>
      </div>
    </>
  );
}
export default OracleConsultantAtWidestreams;
