import Filter from "../components/filter/filter";
import BlogCard from "../components/blog-card/blog-card";
import { allBlogs } from "../utils/content";
import { useState } from "react";

import gsap from "gsap";
function BlogsSection() {
  const [filteredBlogs, setFilteredBlogs] = useState(allBlogs);

  const handleTagSelect = (selectedTag) => {
    if (selectedTag === "") {
      setFilteredBlogs(allBlogs); // If no tag is selected, show all blogs
    } else {
      const filteredBlogs = allBlogs.filter((blog) =>
        blog.tags.includes(selectedTag)
      );
      setFilteredBlogs(filteredBlogs);
    }
    gsap.set("#filtered-blogs", {
      opacity: 0,
      y: -40,
    });
    gsap.to("#filtered-blogs", {
      duration: 1,
      opacity: 1,
      y: 0,

      ease: "power2.outIn",
    });
  };

  const blogsByYear = filteredBlogs.reduce((acc, blog) => {
    if (!acc[blog.year]) {
      acc[blog.year] = [];
    }
    acc[blog.year].push(blog);
    return acc;
  }, {});

  return (
    <div className="blogs-section">
      <h1>My Journal</h1>
      <Filter
        tags={[
          "Development",
          "Content Creation",
          "Marketing",
          "Success Stories",
          "General",
        ]} // Add all available tags here
        onSelectTag={handleTagSelect}
      />
      <div id="filtered-blogs">
        {Object.entries(blogsByYear)
          .sort((a, b) => b[0] - a[0])
          .map(([year, blogs]) => (
            <div key={year}>
              <h2 className="blogs-section__year">{year}</h2>
              <div className="blogs-section__cards">
                {blogs.map((blog) => (
                  <BlogCard
                    key={blog.id}
                    title={blog.title}
                    description={blog.description}
                    tags={blog.tags}
                    imageURL={blog.imageURL}
                    link={blog.link}
                  />
                ))}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
export default BlogsSection;
