import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Main from "./pages/main";
import MyJourney from "./pages/blogs/my-journey";
import "./App.scss";
import EntrepereneursInLebanon from "./pages/blogs/entrepreneurs-in-lebanon";
import YoutubersInLebanon from "./pages/blogs/youtubers-in-lebanon";
import MakingModneyInLebanon from "./pages/blogs/make-money-online-in-lebanon";
import NotFound from "./pages/NotFound";
import OgadsAffiliateMarketingJourney from "./pages/blogs/ogads-affiliate-marketing-journey";
import CommunityManagerAtBizaroob from "./pages/blogs/community-manager-at-bizaroob";
import AccountManagerAtKingsmen from "./pages/blogs/account-manager-at-kingsmen";
import DevrabicCommunity from "./pages/blogs/devrabic-community";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import ShopOnSmLebanon from "./pages/blogs/shop-on-smlebanon";
import InstagramGamingPages from "./pages/blogs/instagram-gaming-pages";
import BadMikeYouTubeChannel from "./pages/blogs/bad-mike-youtube-channel";
import ThugLifeGamingCompilationsPioneer from "./pages/blogs/thug-life-gaming-compilations-pioneer";
import OracleConsultantAtWidestreams from "./pages/blogs/oracle-consultant-at-widestreams";
import WebDeveloperAtUSJ from "./pages/blogs/web-developer-at-usj";
import CryptorabicCryptoCommunity from "./pages/blogs/cryptorabic-crypto-community";
import RankingSalonEAchrafieh from "./pages/blogs/ranking-salon-e-achrafieh";
import SMSSecurityNFTProject from "./pages/blogs/s-m-s-security-nft-project";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<Main />}></Route>
        <Route exact path="/my-journey" element={<MyJourney />}></Route>
        <Route
          exact
          path="/entrepreneurs-in-lebanon"
          element={<EntrepereneursInLebanon />}
        ></Route>
        <Route
          exact
          path="/youtubers-in-lebanon"
          element={<YoutubersInLebanon />}
        ></Route>
        <Route
          exact
          path="/make-money-online-in-lebanon"
          element={<MakingModneyInLebanon />}
        ></Route>
        <Route
          exact
          path="/devrabic-community"
          element={<DevrabicCommunity />}
        ></Route>
        <Route
          exact
          path="/shop-on-smlebanon"
          element={<ShopOnSmLebanon />}
        ></Route>
        <Route
          exact
          path="/s-m-s-security-nft-project"
          element={<SMSSecurityNFTProject />}
        ></Route>

        <Route
          exact
          path="/ranking-salon-e-achrafieh"
          element={<RankingSalonEAchrafieh />}
        ></Route>
        <Route
          exact
          path="/cryptorabic-crypto-community"
          element={<CryptorabicCryptoCommunity />}
        ></Route>
        <Route
          exact
          path="/web-developer-at-usj"
          element={<WebDeveloperAtUSJ />}
        ></Route>
        <Route
          exact
          path="/account-manager-at-kingsmen"
          element={<AccountManagerAtKingsmen />}
        ></Route>
        <Route
          exact
          path="/oracle-consultant-at-widestreams"
          element={<OracleConsultantAtWidestreams />}
        ></Route>
        <Route
          exact
          path="/community-manager-at-bizaroob"
          element={<CommunityManagerAtBizaroob />}
        ></Route>
        <Route
          exact
          path="/ogads-affiliate-marketing-journey"
          element={<OgadsAffiliateMarketingJourney />}
        ></Route>

        <Route
          exact
          path="/thug-life-gaming-compilations-pioneer"
          element={<ThugLifeGamingCompilationsPioneer />}
        ></Route>
        <Route
          exact
          path="/bad-mike-youtube-channel"
          element={<BadMikeYouTubeChannel />}
        ></Route>

        <Route
          exact
          path="/instagram-gaming-pages"
          element={<InstagramGamingPages />}
        ></Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}
export default App;
