import "../App.scss";
import { Canvas, useFrame } from "@react-three/fiber";
import { useRef, useState, useEffect, Suspense } from "react";

import gsap from "gsap";

import { useTexture, Loader } from "@react-three/drei";
import { useMediaQuery } from "react-responsive";
import { Html } from "@react-three/drei";
import HomeSection from "./home-section";
import ServicesSection from "./services-section";
import ContactSection from "./contact-section";
import BlogsSection from "./blogs-section";

function Box({ position, onClickE, index }) {
  const isBiggerScreen = useMediaQuery({ query: "(min-width: 1500px)" });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1200px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1000px)" });
  const changePositionQuery = useMediaQuery({ query: "(max-width: 900px)" });

  const mesh = useRef();

  const texture_1 = useTexture(`textures${index}/1.jpg`);
  const texture_2 = useTexture(`textures${index}/2.jpg`);
  const texture_3 = useTexture(`textures${index}/3.jpg`);
  const texture_4 = useTexture(`textures${index}/4.jpg`);
  const texture_5 = useTexture(`textures${index}/5.jpg`);
  const texture_6 = useTexture(`textures${index}/6.jpg`);
  const black = useTexture(`black.jpg`);
  const animate = useRef(true);
  const setAnimate = () => {
    animate.current = !animate.current;
  };

  useFrame((state, delta) => {
    animate.current && (mesh.current.rotation.x += 0.01);
  });

  return (
    <mesh
      position={position}
      ref={mesh}
      scale={
        isBiggerScreen ? 1.8 : isBigScreen ? 1.5 : isTabletOrMobile ? 1.3 : 1.2
      }
      onClick={() => {
        onClickE();
      }}
      onPointerOver={(event) => setAnimate()}
      onPointerOut={(event) => setAnimate()}
    >
      <boxGeometry args={[1, 1, 1]} />
      <meshStandardMaterial attach="material-0" map={texture_1} />
      <meshStandardMaterial attach="material-1" map={texture_2} />
      <meshStandardMaterial attach="material-2" map={texture_3} />
      <meshStandardMaterial attach="material-3" map={texture_4} />
      <meshStandardMaterial attach="material-4" map={texture_5} />
      <meshStandardMaterial attach="material-5" map={texture_6} />
    </mesh>
  );
}
function Main() {
  const currentYear = new Date().getFullYear();
  const yearTxt = currentYear === 2023 ? "2023" : currentYear;

  const [homeSection, sethomeSection] = useState(true);
  const [blogsSection, setblogsSection] = useState(false);
  const [servicesSection, setservicesSection] = useState(false);
  const [contactSection, setcontactSection] = useState(false);

  const turnHomeSection = () => {
    setservicesSection(false);
    setblogsSection(false);
    setcontactSection(false);
    sethomeSection(true);
  };
  const turnServicesSection = () => {
    sethomeSection(false);
    setblogsSection(false);
    setcontactSection(false);
    setservicesSection(true);
  };
  const turnBlogsSection = () => {
    sethomeSection(false);
    setcontactSection(false);
    setservicesSection(false);
    setblogsSection(true);
  };
  const turnContactSection = () => {
    sethomeSection(false);
    setblogsSection(false);
    setservicesSection(false);
    setcontactSection(true);
  };

  function animateSection(classname) {
    upofthepage.current.scrollIntoView({ behavior: "smooth" });
    gsap.set(classname, {
      opacity: 0,
      y: -40,
    });
    gsap.to(classname, {
      duration: 1,
      opacity: 1,
      y: 0,

      ease: "power2.outIn",
    });
  }

  const [cubePos, setcubePos] = useState(0.8);
  const isBiggerScreen = useMediaQuery({ query: "(min-width: 1500px)" });
  const isBigScreen = useMediaQuery({ query: "(max-width: 1200px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1000px)" });
  const changePositionQuery = useMediaQuery({ query: "(max-width: 900px)" });
  const changePositionQuerySmall = useMediaQuery({
    query: "(max-width: 320px)",
  });

  useEffect(() => {
    // This effect runs when the component mounts and when the URL hash changes
    const handleHashUpdated = () => {
      const hash = window.location.hash;
      switch (hash) {
        case "#blogs":
          turnBlogsSection();
          break;
        case "#home":
          turnHomeSection();
          break;
        case "#contact":
          turnContactSection();
          break;
        case "#services":
          turnServicesSection();
          break;
        default:
          turnHomeSection();
          break;
      }
    };

    // Attach the hash change listener
    window.addEventListener("hashchange", handleHashUpdated);

    // Run the function immediately if the initial hash matches
    if (window.location.hash === "#blogs") {
      turnBlogsSection();
    } else if (window.location.hash === "#home") {
      turnHomeSection();
    } else if (window.location.hash === "#contact") {
      turnContactSection();
    } else if (window.location.hash === "#services") {
      turnServicesSection();
    }

    // Clean up the listener when the component unmounts
    return () => {
      window.removeEventListener("hashchange", handleHashUpdated);
    };
  }, []); // Empty dependency array ensures this effect runs only on mount and unmount

  useEffect(() => {
    isBiggerScreen && setcubePos(0.8);
    isBigScreen && setcubePos(0.7);

    isTabletOrMobile && setcubePos(0.6);
  }, [cubePos, isBigScreen, isBiggerScreen, isTabletOrMobile]);

  const boxArray = [
    {
      position: changePositionQuery
        ? changePositionQuerySmall
          ? [0, 3, 0]
          : [-0.8, 1.5, 0]
        : [cubePos * -5, 0, 0],
      color: "red",
      onClickE: async () => {
        await turnHomeSection();
        animateSection(".home-section");
      },
    },
    {
      position: changePositionQuery
        ? changePositionQuerySmall
          ? [0, 1, 0]
          : [0.8, 1.5, 0]
        : [cubePos * -1.5, 0, 0],
      color: "yellow",
      onClickE: async () => {
        await turnServicesSection();
        animateSection(".services-container");
      },
    },
    {
      position: changePositionQuery
        ? changePositionQuerySmall
          ? [0, -1, 0]
          : [-0.8, -1, 0]
        : [cubePos * 1.5, 0, 0],
      color: "white",
      onClickE: async () => {
        await turnBlogsSection();
        animateSection(".blogs-section");
      },
    },
    {
      position: changePositionQuery
        ? changePositionQuerySmall
          ? [0, -3, 0]
          : [0.8, -1, 0]
        : [cubePos * 5, 0, 0],
      color: "blue",
      onClickE: async () => {
        await turnContactSection();
        animateSection(".contact-section");
      },
    },
  ];

  const upofthepage = useRef();

  return (
    <div className="mainContainer">
      <div ref={upofthepage} className="twodContainer">
        <div className="twodContainer_sub">
          {homeSection && <HomeSection />}
          {servicesSection && <ServicesSection />}
          {blogsSection && <BlogsSection />}
          {contactSection && <ContactSection />}
        </div>
      </div>
      <div className="threedContainer">
        <Canvas
          camera={{
            position: changePositionQuerySmall ? [0, 0, 5.2] : [0, 0, 4.7],
          }}
        >
          <Suspense
            fallback={
              <Html>
                <Loader
                  containerStyles={{ transform: "scale(180%)" }}
                  dataInterpolation={(p) =>
                    `Loading 3d Assets ${p.toFixed(2)}%`
                  } // Text
                />
              </Html>
            }
          >
            <ambientLight />
            <pointLight position={[10, 10, 10]} />
            {boxArray.map((box, i) => {
              return (
                <Box
                  key={i}
                  index={i}
                  stopRotate={box.stopRotate}
                  position={box.position}
                  color={box.color}
                  onClickE={box.onClickE}
                />
              );
            })}
          </Suspense>
        </Canvas>
      </div>

      <div className="copyrightSection">
        {" "}
        © {yearTxt} - Developed by Mike Majdalani
      </div>
    </div>
  );
}

export default Main;
